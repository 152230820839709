.l-main {
  display: grid;
}

.l-header-bg {
  grid-area: 1 / 1 / 4 / 2;
}

.l-header-top {
  display: grid;
  grid-area: 1 / 1 / 2 / 2;
  padding: $spacing-s $spacing-s 0 $spacing-s;
  z-index: 20;

  @media (min-width: rem-calc(768px)) {
    padding: rem-calc(57) rem-calc(57) 0 rem-calc(57);
  }
}

.l-header-top__left {
  grid-area: 1 / 1 / 1 / 2;
}

.l-header-top__right {
  grid-area: 1 / 2 / 1 / 2;
  justify-self: end;
  z-index: 1;
}

.l-header-bottom {
  display: grid;
  grid-template-columns: auto minmax(auto, rem-calc(990)) auto;

  grid-area: 2 / 1 / 3 / 2;
  padding: $spacing-s $spacing-s 0 $spacing-s;
  z-index: 1;
  align-items: center;

  @media (min-width: rem-calc(768px)) {
    padding: rem-calc(57) rem-calc(57) 0 rem-calc(57);
  }
}

.l-header-bottom__left {
  display: flex;
  position: relative;
  align-items: center;
}

.l-content {
  display: grid;
  grid-template-columns: auto minmax(auto, rem-calc(650)) minmax(
      auto,
      rem-calc(340)
    ) auto;
  grid-area: 3 / 1 / 4 / 2;
  z-index: 1;
  padding: $spacing-s;

  @media (min-width: rem-calc(768px)) {
    padding: rem-calc(35) rem-calc(57) 0 rem-calc(57);
  }
}

.l-content__large {
  display: inline-grid;
  overflow: hidden;
  grid-area: 1 / 2 / 4 / 4;
}

.l-content__small {
  grid-area: 1 / 2 / 4 / 4;
  display: inline-grid;
  overflow: hidden;

  @media (min-width: rem-calc(768px)) {
    grid-area: 2 / 2 / 3 / 3;
  }
}
