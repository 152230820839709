h1,
h2,
h3,
h4,
h5 {
  color: $color-secondary;
  font-family: $font-family-sans-serif-bold;
  margin: 0;
}

h1 {
  font-family: $font-family-sans-serif-condensed-bold;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: $color-white;
  font-size: $font-xl;

  @media (min-width: rem-calc(768px)) {
    font-size: rem-calc(64);
  }
}

h2 {
  font-size: $font-xl;
  line-height: 1;
  font-family: $font-family-sans-serif-condensed-bold;
  text-transform: uppercase;
  font-weight: 700;
}

h3 {
  font-size: $font-s;
  line-height: 1;
  text-transform: uppercase;
}

p {
  font-family: $font-family-sans-serif;
  color: $color-secondary;
  font-size: $font-s;
  line-height: 1.6875;
}

b {
  font-family: $font-family-sans-serif-bold;
}

a {
  color: $color-primary;
  text-decoration: none;
}
