//TODO: validate CSS

.c-report-codes {
  padding: 0;

  &__title {
    padding: 0 32px;
    line-height: 80px;
    border-bottom: $border-bottom;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  &__category-title {
    color: $color-grey-light;
    font-size: $font-m;
    margin-bottom: 16px;
  }

  &__error {
    color: $color-tertiary;
    font-size: $font-base;
    margin-top: 8px;
  }

  &__country-sport-selector {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &--separator {
      display: flex;
      gap: 8px;
    }

    select {
      height: 48px;
      width: 216px;
      flex-grow: 0;
    }
  }

  &__generated-codes {

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: $border-bottom;
        font-weight: bold;
        line-height: 48px;
        padding-left: 32px;
        margin: 0;
      }
    }
  }

  &__create {
    text-transform: uppercase;
    margin-left: auto;

    span {
      margin-right: 8px;
    }
  }
}
