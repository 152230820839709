.c-header {
  position: relative;
  height: rem-calc(146);
  background-color: $color-secondary;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background-size: auto 259%;
    opacity: 0.2;
    background-position: 111%;
    background-repeat: no-repeat;
    background-image: url("./../../images/header__graphic-01.svg");
  }

  @media (min-width: rem-calc(768px)) {
    height: rem-calc(300);

    &:before {
      background-position: 60%;
    }
  }
}
