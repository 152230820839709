.c-checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  column-gap: $spacing-xs;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  .c-csc__field & {
    .c-checkbox__label {
      &::before {
        margin-top: rem-calc(15);
      }
    }
  }
}

.c-checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ .c-checkbox__label {
    &::before {
      background-color: $color-primary;
    }

    &::after {
      content: '\e90d';
      font-family: "icomoon";
      font-size: rem-calc(14);
      position: absolute;
      top: 1px;
      left: 3px;
      color: $color-white;
    }
  }

  &:checked:disabled ~ .c-checkbox__label {
    &::before {
      background-color: rgba($color-secondary, .10);
    }

    &::after {
      color: rgba($color-secondary, .65);
    }
  }

  &:disabled ~ .c-checkbox__label {
    &::before {
      border-color: $color-grey-light;
    }
  }
}

.c-checkbox__label {
  position: relative;
  display: flex;

  &::before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    width: rem-calc(20);
    height: rem-calc(20);
    margin: 0 10px 0 0;
    border: rem-calc(2) solid $color-primary;
  }
}
