.c-attachments {
  width: 100%;
  list-style: none;
  padding-left: $spacing-s;
  margin: 0;
}

.c-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: $border-bottom;
  padding: $spacing-xs 0;
  line-height: 1.875;

  [class^="icon-"] {
    font-size: rem-calc(24);
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.c-attachment__link {
  display: inline-flex;
}