.c-logo {
  width: rem-calc(218);
  height: rem-calc(51);
  position: absolute;
  top: rem-calc(56);

  @media (min-width: rem-calc(768px)) {
    width: rem-calc(218);
    height: rem-calc(51);
  }
}
