@font-face {
  font-family: "FS Industrie";
  src: url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff");
  src: url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff")
      format("woff"),
    url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FS Industrie Bold";
  src: url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff");
  src: url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff")
      format("woff"),
    url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff2")
      format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FS Industrie Cd Bold";
  src: url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff");
  src: url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff")
      format("woff"),
    url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff2")
      format("woff2");
  font-weight: bold;
  font-style: normal;
}

@include font-face(icomoon, "#{$font-path}icomoon/icomoon", normal, normal);

$font-family-sans-serif: "FS Industrie", sans-serif, Impact, Helvetica, Arial;
$font-family-sans-serif-condensed-bold: "FS Industrie Cd Bold", sans-serif,
  Impact, Helvetica, Arial;
$font-family-sans-serif-bold: "FS Industrie Bold", sans-serif, Impact, Helvetica,
  Arial;

$font-xxs: rem-calc(8);
$font-xs: rem-calc(14);
$font-base: rem-calc(16);
$font-s: rem-calc(18);
$font-m: rem-calc(20);
$font-ml: rem-calc(24);
$font-l: rem-calc(26);
$font-xl: rem-calc(32);
