.c-table {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    text-transform: uppercase;
    font-family: $font-family-sans-serif-bold;
    border-bottom: $border-bottom;
    color: $color-placeholders;
    padding-bottom: $spacing-xs;
    vertical-align: middle;

    [class^="icon-"]:before {
      font-size: $font-xs;
      padding-left: $spacing-xs;
    }
  }

  tbody tr {
    transition: $transition-base;
    height: rem-calc(48);
    border-bottom: 1px solid $color-border;
    vertical-align: middle;

    &:focus-within,
    &:hover {
      background-color: $color-secondary-lighter;
      cursor: pointer;
    }
  }

  mark {
    background-color: $color-primary;
    color: $color-white;
    padding: rem-calc(4);
    text-transform: uppercase;
    font-family: $font-family-sans-serif-bold;
    font-size: $font-base;

    [class^="icon-"] {
      padding-left: $spacing-xs;
      font-size: $font-s;
    }
  }
}

.is-country {
  font-family: $font-family-sans-serif-bold;
}

.is-date {
  font-size: $font-base;
  color: rgba($color-secondary, 0.7);
}

.is-cta {
  width: rem-calc(24);
  font-size: $font-xs;
  color: $color-primary;
}
