// TODO: validate this CSS
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 20;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  align-items: center;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  margin: 0 auto;
  padding: 32px !important;
}

.ReactModal__Content {
  height: auto;
  position: unset !important;
  width: 500px;
}

.c-new-csc {
  &__input-container {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    gap: 8px;

    input {
      flex-basis: 0 48%;
    }
  }

  &__error {
    color: $color-tertiary;
    font-size: $font-base;
    margin-top: 8px;
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
  }
}

.c-confirmation-modal {
  &__actions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
  }
}
.c-modal {
  border-radius: 0;

  &__column {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    input, select {
      height: 48px;
    }
  }

  &__row {
    margin: 32px 0;
    display: flex;
    flex-direction: row;
    gap: 8px;

    &--reverse {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
