.c-input {
  transition: $transition-base;
  height: rem-calc(48);
  width: 100%;
  padding: $spacing-input;
  background-color: $color-secondary-lighter;
  border: 0;
  border-bottom: rem-calc(2) solid $color-secondary;
  color: $color-secondary;
  font-size: $font-s;
  outline: none;

  &.is-valid,
  &:focus {
    border-bottom-color: $color-primary;
  }

  &.is-error {
    border-bottom-color: $color-tertiary;
  }

  &:read-only, &:disabled {
    cursor: not-allowed;
  }
}

::placeholder {
  color: $color-placeholders;
}

::-moz-placeholder {
  color: $color-placeholders;
}

::-webkit-input-placeholder {
  color: $color-placeholders; 
}
