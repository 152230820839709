.is-active {
  color: $color-primary;
  -bottom-color: $color-primary;
}

.is-valid {
  color: $color-primary;

  &.c-input {
    border-bottom-color: $color-primary;
  }
}

.is-error {
  color: $color-tertiary;

  &.c-input {
    border-bottom-color: $color-tertiary;
  }
}

.is-hidden-small {
  display: none;

  @media (min-width: rem-calc(768px)) {
    display: inline-flex;
  }
}

.is-subtle {
  color: rgba($color-secondary-lighter, 0.7);
  font-size: $font-base;
  text-align: right;
}
