*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
  background: $color-secondary-lighter;
  margin: 0;
  scroll-behavior: smooth;
  color: $color-secondary;

  font-size: $font-s;
  font-family: $font-family-sans-serif;
  font-weight: normal;
  line-height: 1;
  font-kerning: normal;
  text-rendering: optimizeSpeed;
}
