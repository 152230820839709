.c-link {
  transition: $transition-base;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  font-family: $font-family-sans-serif;
  font-size: $font-s;
  cursor: pointer;
  padding: 0;
  color: $color-primary;
  border-bottom: rem-calc(2) solid $color-primary;

  &:hover {
    color: $color-primary-dark;
    border-bottom: rem-calc(2) solid $color-primary-dark;
  }
}
