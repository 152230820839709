.c-pagination {
  display: flex;

  button {
    transition: all 0.2s;
    display: flex;
    align-content: center;
    border: 0;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    width: rem-calc(48);
    height: rem-calc(48);
    color: $color-white;
    font-family: $font-family-sans-serif-condensed-bold;
    font-size: $font-ml;
    margin-right: $spacing-xs;
    cursor: pointer;

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  .is-active {
    background-color: $color-secondary;

    &:hover {
      background-color: $color-secondary-dark;
    }
  }

  .is-more {
    background-color: $color-primary-light;
  }
}
