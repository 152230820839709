// TODO: validate this CSS
.c-new-csc {
  &__input-container {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    gap: 8px;

    input {
      flex-basis: 0 48%;
    }
  }

  &__error {
    color: $color-tertiary;
    font-size: $font-base;
    margin-top: 8px;
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
  }
}