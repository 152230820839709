.c-table-filters {
  display: flex;
  padding: $spacing-m;
  border-bottom: $border-bottom;
  overflow: auto;
  white-space: nowrap;
}

.c-table-filters__filter {
  display: flex;
  align-items: flex-end;
  margin-left: $spacing-xs;
  width: 100%;
  max-width: rem-calc(136);

  &--reset {
    width: auto;
    margin-left: auto;
  }

  &:last-child {
    margin-right: $spacing-xs;
  }
}
