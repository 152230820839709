.c-navigation {
  display: flex;
  justify-items: center;

  [class^="icon-"]:before {
    color: $color-white;
    font-size: rem-calc(24);
  }
}

.c-navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.c-navigation__list-item {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-navigation__text {
  display: flex;
  align-items: center;
  font-family: $font-family-sans-serif-condensed-bold;
  line-height: 24px;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: $color-white;
  font-size: rem-calc(32);
  outline: none;
  padding: rem-calc(12);
  cursor: default;
}

.c-navigation__link {
  display: flex;
  align-items: center;
  font-family: $font-family-sans-serif-condensed-bold;
  line-height: 24px;
  text-transform: uppercase;
  transition: all 0.2s;
  background: transparent;
  border: none;
  color: $color-white;
  font-size: rem-calc(24);
  outline: none;
  padding: rem-calc(12);
  cursor: pointer;

  &.c-navigation__link--active,
  &:hover {
    background-color: $color-secondary-dark;
  }

  span {
    display: flex;
  }

  @media (min-width: rem-calc(768px)) {
    .c-navigation__hamburger {
      margin-left: $spacing-l;
    }
  }
}

.c-subnavigation {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 10;
  background-color: $color-white;
  padding: $spacing-s;
  min-width: rem-calc(258);

  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  border: rem-calc(1) solid $color-secondary-lighter;
  box-shadow: 0 0 rem-calc(4) rem-calc(2) $color-secondary-lighter;

  transform: translate3d(0, 1rem, 0);
}

.c-subnavigation__list-item {
  border-bottom: $border-bottom;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;

  &:last-child {
    border: 0;
  }

  .c-subnavigation__link,
  > a {
    transition: $transition-base;
    border: 0;
    padding: 0;
    margin: rem-calc(10) 0;
    background: transparent;
    font-family: $font-family-sans-serif-bold;
    color: $color-secondary;
    font-size: $font-s;
    text-align: left;
    width: 100%;
    cursor: pointer;

    &:hover,
    &.c-subnavigation__link--current {
      color: $color-primary;
    }
  }
}
