.c-select {
  appearance: none;
  transition: $transition-base;
  height: rem-calc(32);
  border: 0;
  border-bottom: rem-calc(2) solid $color-secondary;
  font-size: $font-s;
  padding: rem-calc(2) rem-calc(30) rem-calc(2) $spacing-xs;
  width: 100%;
  outline: none;
  background-color: $color-secondary-lighter;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0Ij4KPHBhdGggZD0iTTEwMi40IDIzNC43OTNsNDA5LjYgNDA5LjYgNDA5LjYtNDA5LjYgMTAyLjQgMTAyLjQtNDA5LjYgNDA5LjZjLTU2LjU1NCA1Ni41NTQtMTQ4LjI0NiA1Ni41NTQtMjA0LjgwMSAwbC00MDkuNi00MDkuNiAxMDIuNC0xMDIuNHoiPjwvcGF0aD4KPC9zdmc+Cg==");
  background-repeat: no-repeat, repeat;
  background-position: right rem-calc(12) top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-valid,
  &:focus {
    border-bottom-color: $color-primary;
  }

  &.is-error {
    border-bottom-color: $color-tertiary;
  }
}
