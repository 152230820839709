// TODO: validate this CSS
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 20;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  align-items: center;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  border-radius: 0 !important;
  margin: 0 auto;
  padding: 32px !important;
}

.ReactModal__Content {
  height: auto;
  position: unset !important;
  width: 500px;
}

.c-csc {

  &__sub-header {
    line-height: 32px;
    margin: 0;
    border-bottom: $border-bottom;
    padding: 24px 32px;
  }

  &__category-title {
    border-bottom: $border-bottom;
    color: $color-grey-light;
    font-size: $font-s;
    padding-bottom: 8px;
  }

  &__logo,
  &__logo img {
    height: 245px;
    width: 245px;
  }

  &__check-icon {
    color: $color-primary;
    margin-left: 20px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 32px 0;
  }

  &__details-block {
    display: flex;
    flex-direction: column;
    margin-top: 17px;
    width: 618px;
  }

  &__error {
    color: $color-tertiary;
    font-size: $font-base;
    margin-top: 8px;
    display: flex;
    flex-basis: 50%;
    margin-left: auto;
    line-height: 16px;
    padding: 4px 0;
  }

  &__field {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: $box-shadow-bottom-inset;
    line-height: 48px;

    strong,
    input,
    select {
      flex-basis: 50%;
      flex-grow: 0;
      line-height: 48px;
      height: 48px;
    }

    // TODO: implement this in a nicer way
    input.input-button {
      flex-basis: calc(50% - 48px);
      flex-grow: 0;
      line-height: 48px;
      height: 48px;
    }

    strong {
      font-family: $font-family-sans-serif-bold;
      font-size: $font-s;
    }

    &__label {
      font-size: $font-s;
      flex-basis: 50%;
      flex-grow: 0;
      line-height: 48px;
      height: 48px;
    }
  }

  &__handler-button {
    color: $color-black;
    transition: $transition-base;
    height: rem-calc(48);
    background-color: $color-secondary-lighter;
    border: 0;
    border-bottom: rem-calc(2) solid $color-secondary;
    font-size: $font-s;
    padding: $spacing-input;
    width: 48px;
    outline: none;

    &:hover {
      background: $color-secondary-lighter;
      color: $color-black;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}