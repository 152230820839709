.c-users {
  &__icon {
    color: $color-primary;

    ::before {
      height: 18px;
      width: 18px;
    }

    &-confirmed {
      color: $color-primary;
    }

    &-not-confirmed {
      color: $color-tertiary;
    }
  }

  &__error {
    color: $color-tertiary;
  }

  &__footer {
    line-height: 48px;
  }

  &__actions {
    &-button {
      color: $color-black;
      text-transform: none;
      font-size: $font-m;

      &--more {
        color: $color-primary;
        margin-left: auto;
      }
    }

    &-list {
      position: absolute;
      background: white;
      z-index: 500;
      padding: 0 16px;
      list-style-type: none;
      border: $border-bottom;
      right: 0;
      top: 10px;

      &-item {
        line-height: 48px;
      }

      &--visible {
        display: block;
      }

      &--invisible {
        display: none;
      }
    }

    &-container {
      position: relative;
    }

    &-overlay {
      position: fixed;
      cursor: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 50;
    }
  }
}
