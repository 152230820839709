.c-report {
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: auto;
}

.c-report__header {
  padding: $spacing-m $spacing-l;
  border-bottom: $border-bottom;
}

.c-report__error {
  color: $color-tertiary;
  font-size: $font-base;
  margin-top: 8px;
  display: flex;
  flex-basis: 50%;
  margin-left: auto;
  line-height: 16px;
  padding: 4px 0;
}

.c-report__content {
  padding: $spacing-m $spacing-l 0;
}

.c-report__footer {
  padding: $spacing-l;
}

.c-report__row {
  display: grid;
  justify-content: space-between;
  border-bottom: $border-bottom;
  padding: $spacing-xs 0;
  line-height: 1.875;
  grid-template-columns: auto auto;
  grid-template-areas:
    "top top"
    "bottom bottom";
}

.c-report__row-top {
  grid-area: top / span 1;
}

.c-report__row-bottom {
  grid-area: bottom / span 2;
}

.c-report__list {
  width: 100%;
  list-style: none;
  padding-left: $spacing-s;
  margin: 0;
}

.c-report__list-item {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: $border-bottom;
  padding: $spacing-xs 0;
  line-height: 1.875;

  &::before {
    content: "";
    width: rem-calc(4);
    height: rem-calc(4);
    background: $color-secondary;
    display: inline-flex;
    margin-right: $spacing-xs;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
