// Foundation Colors
$color-black: #000000;
$color-white: #ffffff;

// Primary Colors
$color-primary: #4cbf8c;
$color-primary-dark: #3fb27f;
$color-primary-light: rgba($color-primary, 0.35);

$color-secondary: #221c46;
$color-secondary-dark: #150f39;
$color-secondary-light: rgba($color-secondary, 0.5);
$color-secondary-lighter: rgba($color-secondary, 0.05);

$color-tertiary: #ac003e;

// Secundary Colors
$color-warning: #da8732;
$color-warning-light: #d8c193;

// Greys
$color-grey-lighter: #f8f8f8;
$color-grey-light: #b9b9b9;
$color-grey-medium: #838282;

// Other-shadows
$box-shadow-bottom: 0 2px 32px rgba($color-grey-medium, 0.28);
$box-shadow-top: 0 -2px 8px rgba($color-grey-medium, 0.16);
$box-shadow-bottom-inset : inset 0px -2px 0px 0px rgba($color-secondary-lighter, 0.05);

// Form
$color-placeholders: rgba($color-secondary, 0.4);
$color-backdrop: rgba($color-secondary, 0.9);
$color-border: rgba($color-secondary, 0.1);

// borders
$border-bottom: rem-calc(2) solid $color-secondary-lighter;
