.c-button {
  transition: $transition-base;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: rem-calc(9) rem-calc(12);
  font-family: $font-family-sans-serif-condensed-bold;
  text-transform: uppercase;
  font-size: $font-ml;
  cursor: pointer;
  color: $color-primary;
  outline: none;

  &:hover {
    color: $color-primary-dark;
  }

  &--solid {
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: $color-primary-dark;
      color: $color-white;
    }
  }

  &:disabled {
    background-color: $color-primary-light;
    cursor: not-allowed;
  }

  span {
    font-size: rem-calc(18);
    justify-content: center;
    margin: 0 rem-calc(16);
  }

  &--no-margin span {
    margin: 0;
  }

  &--back {
    color: $color-white;
    width: 100%;

    @media (min-width: rem-calc(768px)) {
      position: absolute;
    }
  }
}
